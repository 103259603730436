import React from 'react'

export const METRICS_GROUPS = {
    fraudulent: {
        title: 'Fraudulent',
        groupKey: 'fraudulent',
        description:
            'Invalid events that have been generated by non-human or malicious means',
        coloringPolicy: { redToGreen: false },
        masterMetrics: [
            {
                title: 'Total Fraudulent',
                key: 'af_fraud',
                description: 'Sum of all events flagged as fraudulent',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Bot/Virus',
                key: 'af_fraud_bot_virus',
                description:
                    'Events generated by viruses and bots that were created specifically for committing ad fraud',
            },
            {
                title: 'Hostile Tools',
                key: 'af_fraud_hostile_tools',
                description:
                    'Events generated by tools that can falsify user info (such as emulating a mobile device) or ' +
                    'hijacking a user’s sessions to generate payable events. This is committed using tools ' +
                    'originally created for legitimate purposes (such as QA tools or by hijacking a user’s ' +
                    'legitimate session)',
            },
            {
                title: 'Tunneled Traffic',
                key: 'af_fraud_tunneled_traffic',
                description:
                    'Events where there were attempts to mask the geolocation or hide the true origin of a user and ' +
                    'device. This includes traffic from Data Centers, VPNs, Proxies, TOR, and known sources of fraud',
            },
            {
                title: 'Non Malicious Bots',
                key: 'af_fraud_non_malicious_bots',
                description:
                    'Events generated by legitimate or self-declared automated services. This includes creative ' +
                    'previews, creative scans, and industry vetted spiders and bots (such as from the IAB Tech ' +
                    'Lab list). For CTV this also includes indirect impressions reported by vetted and known ' +
                    'SSAI vendors',
            },
            {
                title: 'View Fraud',
                key: 'af_fraud_view',
                description:
                    'Events where an impression was served but never rendered to the user, obscured or impossible ' +
                    'to view. Also identifies inventory where there is a historical lack of opportunity for an ad ' +
                    'to be in-view',
            },
            {
                title: 'Publisher Fraud',
                key: 'af_fraud_publisher',
                description:
                    'Events where the inventory has been misrepresented, such as: serving on a property different ' +
                    'than reported, serving to incentivized users, or has been historically used for malicious purposes',
            },
            {
                title: 'Reputation',
                key: 'af_fraud_reputation',
                description:
                    'Events generated by users or devices that have a history of irregular usage (high-frequency), ' +
                    'or generate a high rate of invalid events. With Realtime blocking it also identifies inventory ' +
                    'that has an extraordinarily high percentage of IVT detected via monitoring tags',
            },
        ],
    },
    generalIvt: {
        title: 'Standard IVT',
        groupKey: 'general_ivt',
        coloringPolicy: { redToGreen: false },
        description: '',
        masterMetrics: [
            {
                title: 'Total Standard IVT',
                key: 'ivt_givt',
                description:
                    'Traffic identified through routine means of filtration executed through application of lists or with other standardized parameter checks.',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Data Centers',
                key: 'ivt_givt_data_centers',
                description:
                    'Events generated by traffic originating from known data center and hosting IPs.',
            },
            {
                title: 'Disclosed Bots',
                key: 'ivt_givt_disclosed_bots',
                description:
                    'Events generated by bots and automated tools that are legitimate or self-declared automated services.',
            },
            {
                title: 'Known Crawlers',
                key: 'ivt_givt_known_crawlers',
                description:
                    'Events generated by known crawlers and automated tools that are legitimate or self-declared automated services.',
            },
            {
                title: 'Irregular Activity',
                key: 'ivt_givt_irregular_activity',
                description:
                    'Events generated by users or devices that have a history of irregular usage (high-frequency), or generate a high rate of invalid events.',
            },
            {
                title: 'Non-rendered Ads',
                key: 'ivt_givt_non_rendered_ads',
                description:
                    'Events where an ad was served but never rendered to the user or was impossible to view.',
            },
        ],
    },
    sophisticatedIvt: {
        title: 'Enhanced IVT',
        groupKey: 'sophisticated_ivt',
        coloringPolicy: { redToGreen: false },
        description: '',
        masterMetrics: [
            {
                title: 'Total Enhanced IVT',
                key: 'ivt_sivt',
                description:
                    'More difficult to detect situations that require advanced analytics, multi-point corroboration/coordination, significant human intervention, etc., to analyze and identify.',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Proxy Traffic',
                key: 'ivt_sivt_proxy_traffic',
                description:
                    'Events which were forwarded through a proxy connection, masking the true origin of the user and device.',
            },
            {
                title: 'Automated and Emulated Activity',
                key: 'ivt_sivt_automated_emulated_activity',
                description:
                    'Events generated by bots, viruses, and automated tools that mimic user behavior and falsify user and device info (such as emulating mobile and connected TV devices).',
            },
            {
                title: 'Inventory Spoofing',
                key: 'ivt_sivt_inventory_spoofing',
                description:
                    'Events where the inventory has been misrepresented, such as: serving on a property different than reported or obfuscating content for purposes of fraud.',
            },
            {
                title: 'Falsified or Manipulated',
                key: 'ivt_sivt_falsified_or_manipulated',
                description:
                    'Events generated by hijacking a user’s sessions to generate payable events or by reporting false events. Examples include: an unintended click, hijacking legitimate ad opportunities for fraud, and falsifying attribution or measurement data.',
            },
            {
                title: 'Incentivized, Malware, or Out-of-Store',
                key: 'ivt_sivt_incentivized_malware',
                description:
                    'Events which were generated by traffic from incentivized sources, ads injected by malware or adware, and mobile apps that were removed or are unavailable from any official app stores.',
            },
            {
                title: 'Obstructed Ads',
                key: 'ivt_sivt_obstructed_ads',
                description:
                    'Events where a creative was rendered but obstructed by another element or ad. Also identifies inventory where there is a historical lack of opportunity for an ad to be in-view.',
            },
            {
                title: 'Undisclosed Detection',
                key: 'ivt_sivt_undisclosed_detection',
                description:
                    'Events that are categorized as invalid by methods new or unique to Protected Media. This category also identifies publishers whose inventory has historically been used for malicious purposes or ad fraud.',
            },
        ],
    },
    ivtTotalIvt: {
        title: 'IVT',
        groupKey: 'ivt_ivt',
        coloringPolicy: { redToGreen: false },
        description: '',
        containsOnlyMasterMetrics: true,
        masterMetrics: [
            {
                title: 'Total IVT',
                key: 'ivt_total_ivt',
                description: '',
                availableOnSmartAgent: true,
                isMasterMetric: true
            },
        ],
        metricsByCategories: [],
    },
    suspicious: {
        title: 'Suspicious',
        groupKey: 'suspicious',
        description:
            'Events that have been flagged by proprietary logics and have a very high probability of being invalid',
        coloringPolicy: { redToGreen: false },
        masterMetrics: [
            {
                title: 'Total Suspicious',
                key: 'af_susp',
                description:
                    'Sum of all events flagged exclusively as suspicious. Any event that is also flagged as ' +
                    'fraudulent is counted in the Total Fraudulent metric',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Bot/Virus',
                key: 'af_susp_bot_virus',
                description:
                    'Events generated by viruses and bots that were created specifically for committing ad fraud',
            },
            {
                title: 'Hostile Tools',
                key: 'af_susp_hostile_tools',
                description:
                    'Events generated by tools that can falsify user info (such as emulating a mobile device) or ' +
                    'hijacking a user’s sessions to generate payable events. This is committed using tools ' +
                    'originally created for legitimate purposes (such as QA tools or by hijacking a user’s ' +
                    'legitimate session)',
            },
            {
                title: 'Tunneled Traffic',
                key: 'af_susp_tunneled_traffic',
                description:
                    'Events where there were attempts to mask the geolocation or hide the true origin of a user and ' +
                    'device. This includes traffic from Data Centers, VPNs, Proxies, TOR, and known sources of fraud',
            },
            {
                title: 'Non Malicious Bots',
                key: 'af_susp_non_malicious_bots',
                description:
                    'Events generated by legitimate or self-declared automated services. This includes creative ' +
                    'previews, creative scans, and industry vetted spiders and bots (such as from the IAB Tech ' +
                    'Lab list). For CTV this also includes indirect impressions reported by vetted and known ' +
                    'SSAI vendors',
            },
            {
                title: 'View Fraud',
                key: 'af_susp_view',
                description:
                    'Events where an impression was served but never rendered to the user, obscured or impossible ' +
                    'to view. Also identifies inventory where there is a historical lack of opportunity for an ad ' +
                    'to be in-view',
            },
            {
                title: 'Publisher Fraud',
                key: 'af_susp_publisher',
                description:
                    'Events where the inventory has been misrepresented, such as: serving on a property different ' +
                    'than reported, serving to incentivized users, or has been historically used for malicious purposes',
            },
            {
                title: 'Reputation',
                key: 'af_susp_reputation',
                description:
                    'Events generated by users or devices that have a history of irregular usage (high-frequency), ' +
                    'or generate a high rate of invalid events. With Realtime blocking it also identifies inventory ' +
                    'that has an extraordinarily high percentage of IVT detected via monitoring tags',
            },
        ],
    },
    totalIvt: {
        title: 'Invalid Traffic',
        groupKey: 'ivt',
        description:
            'Events that are invalid due to being generated by automated, illegitimate or malicious means',
        coloringPolicy: { redToGreen: false },
        containsOnlyMasterMetrics: true,
        masterMetrics: [
            {
                title: 'Total IVT',
                key: 'total_ivt',
                description:
                    'Sum of all events that are flagged as either fraudulent or suspicious',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [],
    },
    visibility: {
        title: 'Visibility',
        groupKey: 'visibility',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: '1 Second',
            key: 'vi_ad_visibility_1',
            description: 'This refers to the length of the ad session, where >50% of an ad\'s pixels ' +
                '(or where >30% of the pixels from an ad above 242,500 pixels) were visible for the indicated ' +
                'number of seconds. Please note that these measurements are proprietary and ' +
                'do not reflect the MRC standards',
            availableOnSmartAgent: true,
            isMasterMetric: true
        }],
        metricsByCategories: [
            { title: '2 Seconds', key: 'vi_ad_visibility_2' },
            { title: '5 Seconds', key: 'vi_ad_visibility_5' },
            { title: '10 Seconds', key: 'vi_ad_visibility_10' },
            { title: '15 Seconds', key: 'vi_ad_visibility_15' },
            { title: '20 Seconds', key: 'vi_ad_visibility_20' },
            { title: '25 Seconds', key: 'vi_ad_visibility_25' },
            {
                title: 'Custom Visibility',
                key: 'vi_view_custom',
                description:
                    'This refers to the length of the ad session, where the number of pixels ' +
                    ' were visible for the number of seconds indicated in the Custom Visibility dimension.' +
                    ' Please note that these measurements are proprietary and do not reflect the MRC standards',
            },
        ],
    },
    timeOnSite: {
        title: 'Time On Site',
        groupKey: 'time-on-site',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: '1 Second',
            key: 'vi_tos_1',
            description: 'This refers to the ad\'s session on the site, ' +
                'with no reference to where it appears on the screen',
            availableOnSmartAgent: true,
            isMasterMetric: true
        }],
        metricsByCategories: [
            { title: '2 Seconds', key: 'vi_tos_2' },
            { title: '5 Seconds', key: 'vi_tos_5' },
            { title: '10 Seconds', key: 'vi_tos_10' },
            { title: '15 Seconds', key: 'vi_tos_15' },
            { title: '20 Seconds', key: 'vi_tos_20' },
            { title: '25 Seconds', key: 'vi_tos_25' },
        ],
    },
    brandProtection: {
        title: 'Brand Safety (Detected)',
        groupKey: 'brand-safety',
        coloringPolicy: {redToGreen: false},
        masterMetrics: [{title: 'All Categories', key: 'bp_total', availableOnSmartAgent: true, isMasterMetric: true}],
        metricsByCategories: [
            {
                title: 'Adult & Explicit Sexual Content',
                key: 'bp_adult',
                description:
                    'Percentage of ads displayed on apps or websites marked as suitable for adults only, ' +
                    'as well as apps or websites containing any adult content, pornography and nudity',
            },
            {
                title: 'Arms',
                key: 'bp_arms',
                description:
                    'Percentage of ads displayed on apps or websites with arms and ammunition',
            },
            {
                title: 'Crime',
                key: 'bp_crime',
                description:
                    'Percentage of ads displayed on apps or websites with harmful acts to individuals and society and human right violations',
            },
            {
                title: 'Death Injury & Military Conflict',
                key: 'bp_death_injury_military',
                description:
                    'Percentage of ads displayed on apps or websites with death injury or military conflict',
            },
            {
                title: 'Online Piracy',
                key: 'bp_piracy',
                description:
                    'Percentage of ads displayed on pirated websites or apps',
            },
            {
                title: 'Hate Speech & Acts of Aggression',
                key: 'bp_hate_aggression',
                description:
                    'Percentage of ads displayed on apps or websites with hate speech and acts of aggression',
            },
            {
                title: 'Obscenity and Profanity',
                key: 'bp_profanity',
                description:
                    'Percentage of ads displayed on apps or websites with offensive and profane language',
            },
            {
                title: 'Spam or Harmful Content',
                key: 'bp_spam_harmful',
                description:
                    'Percentage of ads displayed on apps or websites with spam or harmful content',
            },
            {
                title: 'Terrorism',
                key: 'bp_terrorism',
                description:
                    'Percentage of ads displayed on apps or websites with terrorism content',
            },
            {
                title: 'Sensitive Social Issues',
                key: 'bp_sensitive_social',
                description:
                    'Percentage of ads displayed on apps or websites with sensitive social issues',
            },
            {
                title: 'Gambling',
                key: 'bp_gambling',
                description:
                    'Percentage of ads displayed on apps or websites marked as gambling, ' +
                    'card playing or casinos',
            },
            {
                title: 'Illegal Drugs',
                key: 'bp_drugs',
                description:
                    'Percentage of ads displayed on apps or websites with illegal drugs and paraphernalia',
            },
            {
                title: 'Alcohol and Tobacco',
                key: 'bp_alcoholtobacco',
                description:
                    'Percentage of ads displayed on apps or websites with alcohol, ' +
                    'tobacco, E-cigarettes and vaping products and/or paraphernalia',
            },
            {
                title: 'Fake News / Misinformation',
                key: 'bp_misinformation',
                description:
                    'Percentage of ads displayed on apps or websites with fake news or misinformation',
            },
        ],
    },
    brandClassified: {
        title: 'Classified',
        groupKey: 'brand-safety-classified',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: 'Classified',
            key: 'bp_classified',
            isMasterMetric: true,
            description:
                'Percentage of ads shown on URLs and hosts that have been successfully classified.',
        }],
        metricsByCategories: [],
    },
    brandProtectionBlocked: {
        title: 'Brand Safety (Blocked)',
        groupKey: 'brand-safety-blocked',
        coloringPolicy: { redToGreen: false },
        masterMetrics: [{
            title: 'Total Blocked',
            key: 'bp_blocked',
            description: 'The actual total blocked traffic',
            excludeFromBlockingCategories: true,
            availableOnSmartAgent: true,
            isMasterMetric: true
        }],
        metricsByCategories: [
            {
                title: 'Non Matching Geolocation',
                key: 'bp_blocked_cat_geo',
                description:
                    'Traffic blocked due to non-matched expected geolocation',
            },
            {
                title: 'Custom URL Lists',
                key: 'bp_blocked_cat_url',
                description:
                    'Traffic blocked based on custom url lists',
            },
            {
                title: 'Custom Bundle Lists',
                key: 'bp_blocked_cat_bundle',
                description:
                    'Traffic blocked based on custom bundle lists',
            },
            {
                title: 'Custom Brand Safety rules',
                key: 'bp_blocked_cat_cust_tokens',
                description:
                    'Traffic blocked based on custom Brand Safety rules',
            },
            {
                title: 'On Page Negative Sentiment',
                key: 'bp_blocked_cat_sent',
                description:
                    'Traffic blocked due to on page negative sentiment',
            },
            {
                title: 'Non Matching Language',
                key: 'bp_blocked_cat_lang',
                description:
                    'Traffic blocked based on non-matched expected language',
            },
            {
                title: 'IVT',
                key: 'bp_blocked_cat_ivt',
                description:
                    'Traffic blocked based on IVT',
            },
            {
                title: 'Brand safety categories severity',
                key: 'bp_blocked_cat_modes',
                description:
                    'Traffic blocked based on defined brand safety categories severity',
            },
        ]
    },
    trafficQuality: {
        title: 'Traffic Intelligence',
        groupKey: 'traffic-quality',
        coloringPolicy: {redToGreen: false},
        masterMetrics: [{title: 'Insights Available', key: 'tq_insights_available', availableOnSmartAgent: true, useTitleInSmartAgent: false, isMasterMetric: true}],
        metricsByCategories: [
            {
                title: 'Notifications & Pops',
                key: 'tq_notifications_pops',
                description:
                    'Sessions that were initiated by a new window/tab. This includes pop-up and pop-under traffic, as well as sessions started from a push notification.',
            },
            {
                title: 'Incentivized Users',
                key: 'tq_incentivized_users',
                description:
                    'Traffic generated by users who are rewarded for viewing or interacting with ads.',
            },
            {
                title: 'Private Browsing',
                key: 'tq_private_browsing',
                description:
                    'Traffic generated by browsers that have a privacy mode enabled that limits tracking, such as incognito mode.',
            },
            {
                title: 'Invalid Supply Chain',
                key: 'tq_invalid_supply_chain',
                description:
                    'Impressions where the supply chain Open RTB object is invalid or missing data (this excludes intentionally falsified supply chain objects, as they will be flagged as IVT).',
            },
            {
                title: 'Ad Density Index',
                key: 'tq_ad_density_index',
                description:
                    'Traffic generated from desktop or mobile browsers sessions where there is a higher than average ad to content or ad to screen ratio.',
            },
            {
                title: 'MFA (Made For Advertising)',
                key: 'tq_mfa',
                description:
                    'Websites that are created solely for the purpose of ad arbitrage. MFA websites typically provide clickbait, sensational headlines' +
                    'and provocative imagery with low-quality content and an extremely large amount of ad banners on page.',
            },
            {
                title: 'Outdated Browser',
                key: 'tq_outdated_ua',
                description:
                    'Traffic generated by users with outdated browsers (such as Chrome 95)',
            },
            {
                title: 'Outdated Operating System',
                key: 'tq_outdated_os',
                description:
                    'Traffic generated by users with Outdated or unsupported operating systems (such as Windows 7)',
            },
        ],
    },
    trafficQualityDetectedClicks: {
        title: 'Clicks',
        groupKey: 'traffic-quality-clicks',
        coloringPolicy: { redToGreen: true },
        containsOnlyMasterMetrics: true,
        masterMetrics: [
            {
                title: 'Detected Clicks',
                key: 'tq_click_detected',
                description: 'Click on ad was detected',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [],
    },
    trafficQualitySourceType: {
        title: 'Traffic Source',
        groupKey: 'traffic-quality-source-type',
        ignoreColoringPolicy: true,
        masterMetrics: [
            {
                title: 'Total',
                key: 'tq_traffic_type_total',
                description: '',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Purchased',
                key: 'tq_traffic_type_purchased',
                description:
                    'Traffic originating from paid sources, such as content recommendation platforms or CPC campaigns.',
            },
            {
                title: 'Social',
                key: 'tq_traffic_type_social',
                description:
                    'Traffic originating from social platforms but not via paid campaigns.',
            },
            {
                title: 'Search',
                key: 'tq_traffic_type_search',
                description:
                    'Traffic originating from search engines (organically and not via paid search campaigns).',
            },
            {
                title: 'Referral',
                key: 'tq_traffic_type_referral',
                description:
                    'Traffic originating outside of the publisher via other campaigns such as email newsletters and push notifications.',
            },
        ],
    },
    sampleRate: {
        title: '**Sampling',
        groupKey: 'sample-rate',
        coloringPolicy: { redToGreen: true },
        masterMetrics: [
            {
                title: 'Sample Rate',
                key: 'sr_measured_rate',
                cellType: 'PercentageCell',
                totalKey: undefined,
                ignoreColoringPolicy: true,
                description: 'Percentage of traffic sampled',
                isDerived: true,
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Calculated Total',
                key: 'sr_calculated_total',
                cellType: 'NumberCell',
                ignoreColoringPolicy: true,
                description: 'Calculated total number of impressions',
            },
            {
                title: 'Confidence Level',
                key: 'sr_confidence_level',
                cellType: 'PercentageCell',
                totalKey: undefined,
                description:
                    'The percentage of all possible samples that can be expected to include the ' +
                    'true measure of the traffic',
            },
        ],
    },
    mrcTrackedAds: {
        title: 'Tracked Ads',
        groupKey: 'mrc-tracked-ads',
        masterMetrics: [
            {
                title: 'Tracked Ads',
                key: 'mrc_tracked_ads',
                cellType: 'NumberCell',
                description:
                    'All transactions on MRC scope (mostly JS and IMG impressions that are not SSAI)',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'GIVT',
                key: 'mrc_tracked_ads_givt',
                totalKey: 'mrc_tracked_ads',
                description:
                    'Transactions in the MRC scope that flagged as GIVT (the percentage is calculated from tracked ads)',
            },
            {
                title: 'SIVT',
                key: 'mrc_tracked_ads_sivt',
                totalKey: 'mrc_tracked_ads',
                description:
                    'Transactions in the MRC scope that flagged as SIVT (the percentage is calculated from tracked ads)',
            },
        ],
    },
    mrcGrossImpressions: {
        title: '*Gross Impressions',
        groupKey: 'mrc-gross-impressions',
        masterMetrics: [
            {
                title: '*Gross Impressions',
                key: 'mrc_gross_impressions',
                cellType: 'NumberCell',
                description:
                    "All rendered and active impressions (Rendered impressions from tracked ads that weren't flagged by our inactivity rules)",
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: '*GIVT',
                key: 'mrc_givt',
                totalKey: 'mrc_gross_impressions',
                description:
                    'Impressions that are flagged as gross impressions and GIVT (the percentage is calculated from gross impressions)',
            },
            {
                title: '*SIVT Decision Rate',
                key: 'mrc_sivt_decided',
                totalKey: 'mrc_gross_impressions',
                cellType: 'PercentageCell',
                description:
                    'Percentage of impressions that had the minimum required signals to determine SIVT decision from gross impressions',
            },
            {
                title: '*SIVT',
                key: 'mrc_sivt',
                totalKey: 'mrc_gross_impressions',
                description:
                    'Impressions that are flagged as gross impressions and SIVT (the percentage is calculated from gross impressions)',
            },
        ],
    },
    mrcNetImpressions: {
        title: '*Net Impressions',
        groupKey: 'mrc-net-impressions',
        masterMetrics: [
            {
                title: '*Net Impressions',
                key: 'mrc_net_impressions',
                totalKey: 'mrc_gross_impressions',
                description:
                    'Impressions that are flagged as gross impressions but not flagged as GIVT (the percentage is calculated from gross impressions)',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: '*Viewable Impressions',
                key: 'mrc_viewable_impressions',
                totalKey: 'mrc_net_impressions',
                description:
                    'All viewable impressions from net impressions (the percentage is calculated from net impressions)',
            },
            {
                title: '*Non Viewable Impressions',
                key: 'mrc_non_viewable_impressions',
                totalKey: 'mrc_net_impressions',
                description:
                    'All non-viewable impressions from net impressions (the percentage is calculated from net impressions)',
            },
            {
                title: '*Viewability Undetermined Impressions',
                key: 'mrc_undetermined_impressions',
                totalKey: 'mrc_net_impressions',
                description:
                    "The number of impressions that we couldn't collect enough information to decide regarding their viewability status from net impressions (the percentage is calculated from net impressions)",
            },
            {
                title: '*Measured Impressions',
                key: 'mrc_measured',
                totalKey: 'mrc_net_impressions',
                cellType: 'PercentageCell',
                description:
                    'The percentage of impressions that we had enough information to decide their viewability status (net impressions - undetermined impressions or viewable impressions + non-viewable impressions) from net impressions',
            },
            {
                title: '*Viewable Rate',
                key: 'mrc_viewable_rate',
                totalKey: 'mrc_measured',
                cellType: 'PercentageCell',
                description:
                    'The percentage of the viewable impressions from net measured impressions',
            },
            {
                title: 'Median Viewability',
                key: 'mrc_median_viewability_in_sec',
                cellType: 'SecondsCell',
                description:
                    'The median viewability time of viewable net impressions',
            },
        ],
    },
    mrcTotalNetImpressions: {
        title: '*Total Net Impressions',
        groupKey: 'mrc-total-net-impressions',
        masterMetrics: [
            {
                title: '*Total Net Impressions',
                key: 'mrc_total_net_impressions',
                totalKey: 'mrc_gross_impressions',
                description:
                    'Impressions that are flagged as gross impressions but not flagged as GIVT or SIVT (the percentage is calculated from gross impressions)',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: '*Viewable Impressions',
                key: 'mrc_sivt_viewable_impressions',
                totalKey: 'mrc_total_net_impressions',
                description:
                    'All viewable impressions from total net impressions (the percentage is calculated from total net impressions)',
            },
            {
                title: '*Non Viewable Impressions',
                key: 'mrc_sivt_non_viewable_impressions',
                totalKey: 'mrc_total_net_impressions',
                description:
                    'All non-viewable impressions from net impressions (the percentage is calculated from total net impressions)',
            },
            {
                title: '*Viewability Undetermined Impressions',
                key: 'mrc_sivt_undetermined_impressions',
                totalKey: 'mrc_total_net_impressions',
                description:
                    "The number of impressions that we couldn't collect enough information to decide regarding their viewability status from total net impressions (the percentage is calculated from total net impressions)",
            },
            {
                title: '*Measured Rate',
                key: 'mrc_sivt_measured',
                totalKey: 'mrc_total_net_impressions',
                cellType: 'PercentageCell',
                description:
                    'The percentage of impressions that we had enough information to decide their viewability status (total net impressions - total net undetermined impressions or total net viewable impressions + total net non-viewable impressions) from total net impressions',
            },
            {
                title: '*Viewable Rate',
                key: 'mrc_sivt_viewable_rate',
                totalKey: 'mrc_sivt_measured',
                cellType: 'PercentageCell',
                description:
                    'The percentage of the viewable impressions from total net measured impressions',
            },
            {
                title: 'Median Viewability',
                key: 'mrc_sivt_median_viewability_in_sec',
                cellType: 'SecondsCell',
                description:
                    'The median viewability time of viewable total net impressions',
            },
        ],
    },
    sampledMRC: {
        title: '**MRC - Sampled',
        groupKey: 'mrc-sampled',
        coloringPolicy: { redToGreen: true },
        masterMetrics: [
            {
                title: 'Sampled Rate',
                key: 'sampled_mrc_measured_rate',
                cellType: 'PercentageCell',
                totalKey: undefined,
                ignoreColoringPolicy: true,
                description: 'Percentage of traffic sampled',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Calculated Total',
                key: 'sampled_mrc_calculated_total',
                cellType: 'NumberCell',
                ignoreColoringPolicy: true,
                description: 'Calculated total number of impressions',
            },
            {
                title: 'Confidence Level',
                key: 'sampled_mrc_confidence_level',
                cellType: 'ConfidenceLevelCell',
                totalKey: undefined,
                ignoreColoringPolicy: true,
                description:
                    'The percentage of all possible samples that can be expected to include the ' +
                    'true measure of the traffic',
            },
        ],
    },
    sampledMRCTrackedAds: {
        title: '**MRC - Sampled Tracked Ads',
        groupKey: 'mrc-sampled-tracked-ads',
        coloringPolicy: { redToGreen: true },
        masterMetrics: [
            {
                title: 'Tracked Ads',
                key: 'sampled_mrc_tracked_ads',
                totalKey: 'sr_calculated_total',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'GIVT',
                key: 'sampled_mrc_tracked_ads_givt',
                totalKey: 'sampled_mrc_tracked_ads',
                ignoreColoringPolicy: true,
            },
            {
                title: 'SIVT',
                key: 'sampled_mrc_tracked_ads_sivt',
                totalKey: 'sampled_mrc_tracked_ads',
                ignoreColoringPolicy: true,
            },
        ],
    },
    sampledMRCGross: {
        title: '**MRC - Sampled Gross Impressions',
        groupKey: 'mrc-sampled-gross',
        coloringPolicy: { redToGreen: true },
        masterMetrics: [
            {
                title: 'Gross Impressions',
                key: 'sampled_mrc_gross_impressions',
                totalKey: 'sr_calculated_total',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Net Impressions',
                key: 'sampled_mrc_net_impressions',
                totalKey: 'sampled_mrc_gross_impressions',
            },
            {
                title: 'GIVT',
                key: 'sampled_mrc_givt',
                totalKey: 'sampled_mrc_gross_impressions',
                ignoreColoringPolicy: true,
            },
            {
                title: 'Total Net Impressions',
                key: 'sampled_mrc_total_net_impressions',
                totalKey: 'sampled_mrc_gross_impressions',
            },
            {
                title: 'SIVT',
                key: 'sampled_mrc_sivt',
                totalKey: 'sampled_mrc_gross_impressions',
                ignoreColoringPolicy: true,
            },
        ],
    },
    clickFraud: {
        title: 'Click Fraud',
        groupKey: 'click-fraud',
        coloringPolicy: { redToGreen: false },
        masterMetrics: [
            {
                title: 'Click Spamming',
                key: 'click_fraud_click_spamming',
                description:
                    'Click Spamming (also known as Click Flooding) is identified where a large number of ' +
                    'clicks are sent in an attempt to obtain the last-click before an install occurs',
                isMasterMetric: true
            },
            {
                title: 'Click Injection',
                key: 'click_fraud_click_injection',
                description:
                    'Click injection occurs when an ad publisher sends a fraudulent click between an app ' +
                    'download and the first app-open, intending to steal the attribution from another source.' +
                    ' This category also includes SDK spoofing',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [
            {
                title: 'Click Spamming- Suspicious',
                key: 'click_fraud_susp_click_spamming',
                description:
                    'Click Spamming (also known as Click Flooding) is identified where a large number of ' +
                    'clicks are sent in an attempt to obtain the last-click before an install occurs',
            },
            {
                title: 'Click Injection- Suspicious',
                key: 'click_fraud_susp_click_injection',
                description:
                    'Click injection occurs when an ad publisher sends a fraudulent click between an app ' +
                    'download and the first app-open, intending to steal the attribution from another source.' +
                    '  This category also includes SDK spoofing',
            },
        ],
    },
    signed: {
        title: 'Signed Pixel',
        groupKey: 'signed',
        coloringPolicy: { redToGreen: true },
        containsOnlyMasterMetrics: true,
        masterMetrics: [
            {
                title: 'Rendered Transactions',
                key: 'sgn_rendered',
                description: '',
                isMasterMetric: true
            },
            {
                title: 'Signed Transactions',
                key: 'sgn_signed',
                description: '',
                isMasterMetric: true
            },
            {
                title: 'Verified Transactions',
                key: 'sgn_verified',
                description: '',
                isMasterMetric: true
            },
        ],
        metricsByCategories: [],
    },
    engagement: {
        title: 'Engagement',
        groupKey: 'engagement',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: 'Total',
            key: 'eng_total',
            description: '',
            availableOnSmartAgent: true,
            isMasterMetric: true
        }
        ],
        metricsByCategories: [
            {
                title: 'Touch',
                key: 'eng_touch',
                description: '',
            },
            {
                title: 'Audio',
                key: 'eng_audio',
                description: '',
            },
            {
                title: 'Play',
                key: 'eng_play',
                description: '',
            },
            {
                title: 'Screen',
                key: 'eng_screen',
                description: '',
            },
        ],
    },
    engagementScore: {
        title: 'Engagement',
        groupKey: 'engagement_score',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: 'Score',
            key: 'eng_score',
            description: 'The score for the combination of the user’s many  interactions with the ad and the content, ' +
                'including the scrolling speed of a user across a web page and hovering over the ad, ' +
                'to interactions of the user within a mobile app.',
            availableOnSmartAgent: true,
            isMasterMetric: true,
            cellType: 'ScoreCell',
            totalKey: undefined,
        }
        ],
        containsOnlyMasterMetrics: true,
        metricsByCategories: []
    },
    exposure: {
        title: 'Exposure',
        groupKey: 'exposure',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: 'Score',
            key: 'exp_score',
            description: 'The score for the quality of the placement’s presentation of the ad to the user, ' +
                'using data points such as the ratio of ad to content, ad to screen, the ad load of the content, ' +
                'to the amount of time it appears in view.',
            availableOnSmartAgent: true,
            isMasterMetric: true,
            cellType: 'ScoreCell',
            totalKey: undefined,
        }
        ],
        containsOnlyMasterMetrics: true,
        metricsByCategories: []
    },
    attention: {
        title: 'Attention',
        groupKey: 'attention',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: 'Score',
            key: 'att_score',
            description: 'The combined score of exposure and engagement.',
            availableOnSmartAgent: true,
            isMasterMetric: true,
            cellType: 'ScoreCell',
            totalKey: undefined,
        }
        ],
        containsOnlyMasterMetrics: true,
        metricsByCategories: []
    },
    unified: {
        title: 'Unified Metrics',
        groupKey: 'unified',
        coloringPolicy: {redToGreen: true},
        masterMetrics: [{
            title: 'Trusted Impressions',
            key: 'trusted_impressions',
            description: 'Total net viewable brand-safe impressions (Viewable impressions that weren\'t flagged by any of the GARM brand-safety categories)',
            availableOnSmartAgent: true,
            useTitleInSmartAgent: false,
            isMasterMetric: true
        }
        ],
        containsOnlyMasterMetrics: true,
        metricsByCategories: []
    },
    // taxonomy: {
    //     title: 'Taxonomy',
    //     groupKey: 'taxonomy',
    //     ignoreColoringPolicy: true,
    //     masterMetrics: [
    //         {
    //             title: 'IAB Categories',
    //             key: 'iab_cats',
    //             cellType: 'TextCell',
    //             description: 'Top IAB categories',
    //             toggleHandler: 'toggleShowIABSubcategories',
    //             toggleText: 'Show IAB Subcategories: ',
    //         },
    //     ],
    //     metricsByCategories: [],
    // },
    videoEventsAndCapabilities: {
        title: 'Video Events and Capabilities',
        groupKey: 'video_events_capabilities',
        masterMetrics: [{
            title: 'Ad Impression',
            key: 'video_events_imp',
            description:
                'This metric tracks the number of times an ad impression is reported by the video player or publisher',
            availableOnSmartAgent: false,
            useTitleInSmartAgent: false,
            isMasterMetric: true
        }],
        metricsByCategories: [{
            title: 'Ad Started',
            key: 'video_event_start',
            description:
                'This metric tracks when an ad begins playing, indicating that the ad has successfully started'
        },
            {
                title: 'Skipped Ads',
                key: 'video_events_skipped',
                description:
                    'This metric tracks the number of ads skipped by users, helping measure how often viewers choose not to complete an ad'
            },
            {
                title: 'First Quartile (25%)',
                key: 'video_events_1_q',
                description:
                    'This metric tracks the number of times an ad is played through the first 25% of the ad'
            },
            {
                title: 'Second Quartile (50%)',
                key: 'video_events_2_q',
                description:
                    'This metric tracks the number of times an ad is played through the 50% mark of the ad'
            },
            {
                title: 'Third Quartile (75%)',
                key: 'video_events_3_q',
                description:
                    'This metric tracks the number of times an ad is played through the 75% mark of the ad'
            },
            {
                title: 'Ad Completed',
                key: 'video_events_complete',
                description:
                    'This metric tracks the number of times an ad is played through completion'
            },
            {
                title: 'Ad Muted',
                key: 'video_events_mute',
                description:
                    'This metric tracks when an ad is muted, either automatically or manually by users'
            },
            {
                title: 'Ad Unmuted',
                key: 'video_events_unmute',
                description:
                    'This metric tracks when a muted ad is unmuted by the viewer, showing when sound is restored, or if the player reports the ad as unmuted at start'
            },
            {
                title: 'Video Muted',
                key: 'video_capabilities_muted',
                description:
                    'This metric tracks if a video remained muted throughout its duration or became unmuted at any point'
            },
            {
                title: 'Video Audible',
                key: 'video_capabilities_audible',
                description:
                    'This metric tracks if a video was audible at any point, indicating that the viewer heard some portion of the ad'
            },
            {
                title: 'Audio Status Unknown',
                key: 'video_capabilities_audio_unknown',
                description:
                    'This metric tracks  cases where it’s unclear whether a video was muted or audible, such as on platforms that don’t provide audio signals'
            },
            {
                title: 'Small Player',
                key: 'video_capabilities_small_player',
                description:
                    'This metric tracks the number of cases where the ad size meets the small player categorization as defined by the IAB'
            },
            {
                title: 'Larger Player',
                key: 'video_capabilities_larger_player',
                description:
                    'This metric tracks the number of cases where the ad size meets the large player categorization as defined by the IAB'
            },
        ]
    },
    videoViewability: {
        title: 'Video Viewability',
        groupKey: 'video_viewability',
        masterMetrics: [{
            title: 'Viewability Eligible',
            key: 'video_viewability_eligible',
            description:
                'This metric tracks the number of cases where it is possible to measure or infer the viewability of the ad',
            availableOnSmartAgent: false,
            useTitleInSmartAgent: false,
            isMasterMetric: true
        }],
        metricsByCategories: [{
            title: 'Viewable Ad',
            key: 'video_viewable',
            description:
                'This metric tracks whether the ad was viewable, either through meeting the IAB definition for in-app and OLV, or was fully complete on CTV in a verified on-screen evironment'
        },
            {
                title: 'Viewable on Complete (VoC)',
                key: 'video_viewable_on_complete',
                description:
                    'This metric tracks the number of impressions that were fully visible when completed'
            },
            {
                title: 'Audible and Viewable on Complete (AVoC)',
                key: 'video_viewable_and_audible_on_complete',
                description:
                    'This metric tracks the number of impressions that were both audible and visible at the time of completion'
            }
        ]
    },

}

export const SOLUTIONS = {
    fraud: {
        title: 'Anti-Fraud',
        description:
            'Examine how much fraud exists within your traffic sources, and what kinds of fraud are most common',
        metricsGroups: [
            METRICS_GROUPS.fraudulent,
            METRICS_GROUPS.suspicious,
            METRICS_GROUPS.totalIvt,
        ],
    },
    ivt: {
        title: 'Invalid Traffic (β)',
        description: '',
        metricsGroups: [
            METRICS_GROUPS.generalIvt,
            METRICS_GROUPS.sophisticatedIvt,
            METRICS_GROUPS.ivtTotalIvt,
        ],
    },
    view: {
        title: 'Visibility',
        description: 'Understand whether or not your ads are visible to humans',
        metricsGroups: [METRICS_GROUPS.visibility, METRICS_GROUPS.timeOnSite],
    },
    brand: {
        title: 'Brand Safety',
        description:
            'Ensure that your ads are running in a non-offensive environment',
        metricsGroups: [METRICS_GROUPS.brandProtection, METRICS_GROUPS.brandClassified, METRICS_GROUPS.brandProtectionBlocked],
    },
    quality: {
        title: 'Traffic Intelligence',
        description: 'Analyze inventory with high-level statistics',
        metricsGroups: [
            METRICS_GROUPS.trafficQuality,
            METRICS_GROUPS.trafficQualitySourceType,
            METRICS_GROUPS.trafficQualityDetectedClicks,
        ],
    },
    signed: {
        title: 'Signed Pixel',
        description: '',
        metricsGroups: [METRICS_GROUPS.signed],
        containsOnlyMasterMetrics: true,
    },
    mrc: {
        title: 'MRC',
        description: 'Media Ratings Council accredited metrics for GIVT & SIVT',
        metricsGroups: [
            METRICS_GROUPS.mrcTrackedAds,
            METRICS_GROUPS.mrcGrossImpressions,
            METRICS_GROUPS.mrcNetImpressions,
            METRICS_GROUPS.mrcTotalNetImpressions,
        ],
    },
    sample_rate: {
        title: 'Sample Rate',
        description:
            'Ensure that you are using the correct sample size in order to reach a fraud detection confidence level that suits you',
        metricsGroups: [METRICS_GROUPS.sampleRate],
        forMasterUsers: true,
    },
    sampled_mrc: {
        title: 'MRC - Sampled',
        description: 'Media Ratings Council accredited metrics for GIVT & SIVT',
        metricsGroups: [
            METRICS_GROUPS.sampledMRC,
            METRICS_GROUPS.sampledMRCTrackedAds,
            METRICS_GROUPS.sampledMRCGross,
        ],
        forMasterUsers: true,
    },
    click_fraud: {
        title: 'Click Fraud',
        description:
            'Examine how much click fraud exists within your traffic sources. ' +
            'Click fraud encompasses fraudulent or forced click reporting',
        metricsGroups: [METRICS_GROUPS.clickFraud],
        forMasterUsers: true,
    },
    unified: {
        title: 'Unified',
        description:
            "Total net viewable brand-safe impressions (Viewable impressions that weren't flagged by any of the GARM brand-safety categories)",
        metricsGroups: [METRICS_GROUPS.unified],
        containsOnlyMasterMetrics: true,
    },
    engagement: {
        title: 'Attention',
        description: '',
        metricsGroups: [METRICS_GROUPS.engagement, METRICS_GROUPS.engagementScore, METRICS_GROUPS.exposure, METRICS_GROUPS.attention],
    },
    // taxonomy: {
    //     title: 'Taxonomy',
    //     description: '',
    //     metricsGroups: [METRICS_GROUPS.taxonomy],
    // },
    video: {
        title: 'Video',
        description: 'This group tracks key events and engagement levels for video ads, helping measure overall ad performance across platforms.',
        metricsGroups: [METRICS_GROUPS.videoEventsAndCapabilities, METRICS_GROUPS.videoViewability],
    },

}

export const INTEGRATION_TEMPLATES = (
    JsParametersString,
    ImgParametersString
) => ({
    JS: `<script type="text/javascript"
             src="https://js.ad-score.com/score.min.js?${JsParametersString}"
             async>
</script>
<noscript>
    <img src="https://data.ad-score.com/img?s=ns&${ImgParametersString}"
              alt="#"
              width="1"
              height="1" />
</noscript>`,
    IMG: `https://data.ad-score.com/img?${ImgParametersString}`,
})

export const PID_COLUMN = {
    title: 'PID',
    dataIndex: 'pid',
    render: (pid) => (
        <span>
            {' '}
            {pid.pid} - {pid.name}{' '}
        </span>
    ),
}
